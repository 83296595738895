import * as icons from "@chakra-ui/icons";
import { extendTheme } from "@chakra-ui/react";

// Evaluated at build time, see `gatsby-ssr.js`
export const GOOGLE_FONTS = ["Roboto", "Merriweather"];
export const FONT_WEIGHTS = ["400", "400i", "700", "700i"];

const theme = extendTheme({
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    palette: {
      50: "#e8f3ff",
      100: "#cfd8e3",
      200: "#b5bdcc",
      300: "#97a3b4",
      400: "#7b899d",
      500: "#626f84",
      600: "#4b5768",
      700: "#343e4b",
      800: "#1e2530",
      900: "#070c18"
    }
  },
  fonts: {
    body: `${GOOGLE_FONTS[0]}, sans-serif`,
    heading: `${GOOGLE_FONTS[1]}, serif`
  },
  icons,
  animation: {
    speed: {
      normal: "0.3s",
      normalMs: 300
    }
  }
});

export default theme;
