import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import theme from "./theme";
import { WrapRootElementBrowserArgs } from "gatsby";

export const themeProviderWrapper = (args: WrapRootElementBrowserArgs) => {
  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      {args.element}
    </ChakraProvider>
  );
};
